import React, {FormEvent, FormEventHandler, InputHTMLAttributes, useCallback, useState} from "react";
import imageNewsletter from '@images/examples/newsletter.png'
import {usePage} from "@inertiajs/react";
import styles from './Subscribe.module.scss'
import InputError from "@/Components/InputError";
import {relativeRoute} from "@/helpers/relativeRoute";
import useRecaptcha from "@/helpers/useRecaptcha";
import useAjaxForm from "@/helpers/useAjaxForm";


export default function NewsletterSubscribe() {



  const {verify} =  useRecaptcha()

  // const {csrf_token} = usePage().props


  const { data, setData, post, processing, success, setSuccess,  errors, setError, clearErrors, reset } = useAjaxForm({
    name: '',
    email: '',
    recaptcha: '',
    // _token: csrf_token,
  });


  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {

    e.preventDefault();


    const recaptcha = await verify();

    await post(relativeRoute('newsletter-subscribe'), {
      extraData: {recaptcha}
    })


  };


  return <section className={styles.container}>
    <div className={`container ${styles.subContainer}`}>
      <div className={styles.formContainer}>

        <h2>De nieuwste verhalen in jouw mailbox</h2>

        {success ? (
          <div>
            <p>Je hebt je ingeschreven in de nieuwsbrief.</p>
            <button onClick={() => setSuccess(false)}>Opnieuw</button>
          </div>
        ) : (<>
          <p>Wekelijks de nieuwste verhalen van Mensenlinq.nl ontvangen in jouw mailbox? Schrijf je in!</p>
          <form onSubmit={handleSubmit}>
            <fieldset disabled={processing}>
              <div className={styles.field}>
                <label htmlFor="newsletter__name">Naam</label>
                <input
                  type="text"
                  value={data.name}
                  onChange={(e) => setData('name', e.target.value)}
                  id="newsletter__name"
                  placeholder="Jouw naam"
                />
                <InputError message={errors.name}/>
              </div>
              <div className={styles.field}>
                <label htmlFor="newsletter__email">E-mailadres</label>
                <input
                  type="text"
                  value={data.email}
                  onChange={(e) => setData('email', e.target.value)}
                  id="newsletter__email" placeholder="Jouw e-mailadres"/>
                <InputError message={errors.email}/>
              </div>
              <div>

                <button>
                  Inschrijven
                </button>
                <InputError message={errors.recaptcha}/>
                {/*<GoogleReCaptcha onVerify={handleVerify} />*/}

              </div>
            </fieldset>
          </form>
        </>)}
        <p>Je kunt je op elk moment uitschrijven. Voor meer informatie, lees ons <a
          href={relativeRoute('privacy-statement')} target={'_blank'}>privacy statement</a>.</p>
      </div>
    </div>
    <img src={imageNewsletter} alt=""/>
  </section>

}
